import { common } from '@local/web-design-system/dist/styles/common';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        ...common(theme).thinScrollBar,
        backgroundColor: '#121a1c',
        flexGrow: 1,
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    errorArea: {
        display: 'flex',
        justifyContent: 'space-around',
        backgroundColor: 'white',
    },
    contentArea: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    heroImage: {
        minHeight: '16em',
        minWidth: '16em',
        maxHeight: '40vh',
        maxWidth: '40vw',
    },
    heroTitle: {
        fontSize: '1.6rem',
        fontWeight: 'bold',
        color: '#ffffffde',
    },
    footer: {
        padding: '12px',
        textAlign: 'center',
        backgroundColor: '#000000de',
        color: '#fff',
    }
}));
