import { LoginError } from '@local/login';
import { getLoginError } from '@local/login/dist/store/sessionStorageHelpers/loginErrorHelper';
import Grid from '@mui/material/Grid/Grid';

import { Header } from './header/Header';
import { useStyles } from './LandingPage.styles';

const ErrorContainer = () => {
  const { classes } = useStyles();
  return (
    <Grid container className={classes.errorArea}>
      <LoginError />
    </Grid>
  );
};

export const LandingPage = () => {
  const { classes } = useStyles();
  const showErrorBanner = getLoginError();

  return (
    <div className={classes.root}>
      {showErrorBanner && <ErrorContainer />}
      <Header />
      <main className={classes.contentArea}>
        <img src="/img/SeequentLabsLogo.png" alt="Seequent Labs" className={classes.heroImage} />
        <h1 className={classes.heroTitle}>Coming soon...</h1>
      </main>
      <footer className={classes.footer}>© Seequent, The Bentley Subsurface Company</footer>
    </div>
  );
};
