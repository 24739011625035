import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';

import { useStyles } from './Header.styles';


export const Header = () => {
    const { classes } = useStyles();

    return (
        <AppBar position="static" className={classes.appBar}>
            <Toolbar>
                <Box sx={{ flexGrow: 1 }}>
                    <Link href="https://www.seequent.com/" target="_blank" rel="noopener">
                        <img
                            src="img/SeequentLabsWhite.svg"
                            // src="img/SeequentEvoWhite.svg"
                            alt="SeequentLabs"
                            className={classes.logo}
                        />
                    </Link>
                </Box>
                <Stack spacing={2} direction="row" alignItems="center">
                    <Button
                        color="primary"
                        variant="outlined"
                        size="large"
                        type="button"
                        // onClick={handleLogin}
                        // href={loginUrl}
                        className={classes.loginButton}
                        automation-id="login-form-sign-in"
                    >
                        Login
                    </Button>
                </Stack>
            </Toolbar>
        </AppBar>
    );
};
